import React from "react";
import ReactDOM from "react-dom";
// import  BasicModal  from './Modaltask';

// import App from './App';
//import Dashboard2 from "./Dashboard";
//import Dashboard2 from './Dashboard2';
// import DataGridDemo from './Datagrid';

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { CookiesProvider } from "react-cookie";

// ReactDOM.render(<Dashboard2 />, document.getElementById('root'));
//ReactDOM.render(<BasicModal />, document.getElementById('root'));

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById("root")
);