import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

function formatDate(ts) {
return (new Date(ts).toLocaleString('en-US'))
}
const columns = [
  { field: 'id', headerName: 'ID', width: 150 },

  {
    field: 'flowname',
    headerName: 'Flow',
    width: 250,
    editable: false,
  },
  {
    field: 'taskname',
    headerName: 'Task',
      width: 250,
    editable: false,
  },
  {
    field: 'dt',
    headerName: 'Timestamp',
    width: 250,
    editable: false,
    valueFormatter: (params) => { return (formatDate(params.value));},
  },

  {
    field: 'status',
    headerName: 'Status',
      width: 200,
    editable: false,
  },

];


export default function DataGridDemo(run) {
 
  const json = JSON.parse(JSON.stringify(run.run_arr).split('_id').join('id'));
  return (
    <div style={{ height: 400, width: '100%' }}>
      <h3>Recent run status</h3>
      <DataGrid
        rows={json}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // checkboxSelection 
        disableSelectionOnClick
      />
    </div>
  );
}
