import * as React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Dashboard2 from "./Dashboard";
import SignIn from "./Login";
import { useCookies } from "react-cookie";
import SignOut from "./signout"
import ChangePassword from "./ChangePassword";
import "./style/App.css";


function RequireAuth({ children }) {
  let location = useLocation();
  const [cookies] = useCookies(["token"]);
  if (!cookies.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Navigate to="/dashboard" />;
            </RequireAuth>
          }
        />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signout" element={<SignOut />} />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard2 />
            </RequireAuth>
          }
        />
        <Route
          path="/changepassword"
          element={
            <RequireAuth>
              <ChangePassword />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}