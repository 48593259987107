import * as React from "react";
import { Routes, Route, Link, useLocation, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function SignOut() {
  let location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  removeCookie("token");

  return <Navigate to="/signin" state={{ from: location }} replace />;
}