import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
const config = require('./config.json');

function formatDate(ts) {
  return (new Date(ts).toLocaleString('en-US'))
  }

const columns = [
  { field: 'id', headerName: 'ID', width: 150 },
  {
    field: 'task_desc',
    headerName: 'Task Desc',
      width: 250,
    editable: false,
  },
  {
    field: 'dt',
    headerName: 'Timestamp',
      width: 200,
    editable: false,
    valueFormatter: (params) => { return (formatDate(params.value));},
  },
  {
    field: 'status',
    headerName: 'Status',
      width: 200,
    editable: false,
  },
  {
    field: 'msg',
    headerName: 'Message',
      width: 2000,
    editable: false,
  },
  {
    field: 'log',
    headerName: 'Log file',
      width: 600,
    editable: false,
    renderCell: (params) => (
      <Link href={config.BACKENDHOST + '/api/files/log/'+ params.value}>{params.value}</Link>
    )
    },
  {
    field: 'dump',
    headerName: 'Dump file',
      width: 600,
    editable: false,
    renderCell: (params) => (
   //   <Link href={`http://localhost:5050/api/files/log/dump/${params.value}`}>{params.value}</Link>
   <Link href={config.BACKENDHOST + '/api/files/log/dump/'+ params.value}>{params.value}</Link>
    )
  }


];


export default function DataGridDemoImport(imports) {
  console.log(imports);
  const json = JSON.parse(JSON.stringify(imports.imports_arr).split('_id').join('id'));
  
  return (

    <div style={{ height: 400, width: '100%' }}>
      <h3>Recent Anaplan Imports </h3>
      <DataGrid
        rows={json}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // checkboxSelection 
        disableSelectionOnClick
      />
    </div>
  );
}
