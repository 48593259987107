import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import LayersIcon from '@mui/icons-material/Layers';
import BasicModal from './Modaltask';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Axios from "axios";
const { Sel } = require('./PeriodSel.js');


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  border: 'none',
  outline: 'none',
  color: theme.palette.text.secondary,
}));

const config = require('./config.json');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  pt: 2,
  px: 4,
  pb: 3,
};

const modalstyles = theme => ({
  modal: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'hidden',
    height: '100%',
    maxHeight: 500,
    display: 'block'
  },
  header: {
    padding: '12px 0',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    padding: 12,
    overflow: 'scroll'
  }
});
//--

function PeriodSel() {
  const SelList =
    Sel.map((element) =>
      <MenuItem value={element.value}>{element.label}</MenuItem>)

  console.log(SelList);
  return SelList;

}

function PeriodSelect(flow, handleQuery) {
  const [period, setPeriod] = React.useState('');

  const handleChange = (event) => {
    setPeriod(event.target.value);
    handleQuery(event.target.value);

  };
  //console.log(flow);
  if ((flow.varsel) && (flow.varsel != '') && (flow.varsel.length > 0)) {
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl required sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="period-select">Period</InputLabel>
          <Select
            labelId="period-select"
            id="per-select"
            value={period}
            label="Period"
            onChange={handleChange}
          >{PeriodSel()}
          </Select>
        </FormControl>
      </Box>
    );
  } else return ''
}

//--

function run_flow(flow, query, handleClose) {
  let url = config.BACKENDHOST +
    '/api/run/' + flow;
  if ((query != '') && (query)) url = url + '?' + query;

  alert('Data flow is running. Close this popup then Wait till Flow window is closed ');
   fetch(url, {
  credentials: 'include'
})
.then(response => response.json())
.then(data => {
  console.log(data);
  handleClose();

});   
}

function FlowList(props) {
  const flows = props.flows;
  const listFlows = flows.map((flow) =>
    <ListItem button onClick={() => {
      props.set_flow(flow.flowname);
      if (flow.flowname != 'ALL') {
        props.handleOpen();
      }
    }}>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary={flow.flow_desc} />
    </ListItem>
  );
  return (
    listFlows
  );
}


export function MainListItems(props) {
  let flow = props.flow_arr;
  let tasks = props.task_arr;
  let set_flow = props.set_flow;
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleQuery = (period) => {
    setQuery('var$01=' + period);
  };


  const flows = flow.map(f => f.flowname);
  let f =
    <div>
      <ListSubheader inset><h2>Data Flows</h2></ListSubheader>
      <FlowList flows={flow} set_flow={set_flow} handleOpen={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ overflow: 'hidden' }}
      >
        <Box sx={{ ...style, width: 800 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ flex: 1 }}>
            Data Flow: {(flow[1].flowname)} {(flow[1].flow_desc)}
          </Typography>
          <Typography id="modal-modal-description" align="right" style={{ flex: 1 }}>
            {PeriodSelect((flow[1]), handleQuery)}
          </Typography>
          <Typography id="modal-modal-description" align="right" style={{ flex: 1 }}>
            <Button size="medium" variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                run_flow(flow[1].flowname, query, handleClose)
              }}
            >Execute Flow</Button>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <BasicModal flow={flow} tasks={tasks} />
          </Typography>
        </Box>
      </Modal>
    </div>;
  return f;
};









