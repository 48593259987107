import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentIcon from '@mui/icons-material/Assignment';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  flexDirection: 'column' ,
  p: 4,
};


function TaskParams(task,task_arr) {
  for (let j = 0; j < task_arr.length; j++) {
    if (task_arr[j].taskname == task) { 
    return (<JSONPretty id="json-pretty" data={task_arr[j]}></JSONPretty>)
 };
};
}



function Task_Desc(task,task_arr) {
  for (let j = 0; j < task_arr.length; j++) {
         if (task_arr[j].taskname == task) { 
         return task_arr[j].task_desc;
      };
    };
}

function TaskList(props) {
  const tasks = props.tasks;
  const task_arr = props.task_arr;
  const ListTask = tasks.map((task) =>
<ListItem button  onClick={() => {
        props.ChildClicked(task);
}}>
 <ListItemIcon>
 <AssignmentIcon />
 </ListItemIcon>
 <ListItemText primary={Task_Desc(task,task_arr)}  />
</ListItem>
 );
 return (
   ListTask
 );
}

export default function BasicModal(props) {
  const flow = props.flow;
  const tasks = props.tasks;
  const [open, setOpen] = React.useState(false);
  const [SelectedTask, setSelectedTask] = React.useState('');
  let ShowChild = false;
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const ChildClicked = (task) => {
    setSelectedTask(task);
    setOpen(true);
  };


return (

  <React.Fragment>
    <h6>Task Sequence </h6>
     <TaskList tasks={(flow[1].tasks)}  task_arr={tasks} ChildClicked={ChildClicked} /> 
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 600 }}>
          <h4 id="child-modal-title">Task: {Task_Desc(SelectedTask,tasks) } - {SelectedTask}</h4>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Task Parameters 
              {TaskParams(SelectedTask,tasks)}  
          </Typography>
          <Button onClick={handleClose}>Close </Button>
        </Box>
      </Modal>
      </React.Fragment>
  );
}