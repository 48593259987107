import axios from "axios";
import { Cookies } from "react-cookie";
const cfg = require('./config.json');

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const cookies = new Cookies();
  const TOKEN = cookies.get('token');
  config.headers.token = TOKEN;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const apiService = {
  isAuthenticated: false,
  signIn: (data) => {
    let url =   cfg.BACKENDHOST + 
    '/api/authenticate';
    return axios.post(url, data);
  },
  changePassword: (data) => {
    let url =  cfg.BACKENDHOST + 
     '/api/user/updatepassword';
    return axios.post(url, data);
  }

};

export default apiService;