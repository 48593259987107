import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

function formatDate(ts) {
  return (new Date(ts).toLocaleString('en-US'))
  }

const columns = [
  { field: 'id', headerName: 'ID', width: 150 },

  {
    field: 'flowname',
    headerName: 'Flow',
    width: 150,
    editable: false,
  },
  {
    field: 'taskname',
    headerName: 'Task',
      width: 200,
    editable: false,
  },
  {
    field: 'dt',
    headerName: 'Timestamp',
      width: 200,
    editable: false,
    valueFormatter: (params) => { return (formatDate(params.value));},
  },
  {
    field: 'logtype',
    headerName: 'Type',
      width: 100,
    editable: false,
  },
  {
    field: 'logmsg',
    headerName: 'Log Msg',
      width: 650,
    editable: false,
  },

];


export default function DataGridDemoLog(log) {
  //console.log(log);
  const json = JSON.parse(JSON.stringify(log.log_arr).split('_id').join('id'));
  return (

    <div style={{ height: 400, width: '100%' }}>
      <h3>Recent Log Messages </h3>
      <DataGrid
        rows={json}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // checkboxSelection 
        disableSelectionOnClick
      />
    </div>
  );
}
