import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import apiService from "./api.service";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { AppBar } from "@mui/material";
import { IconButton } from "@mui/material";
import { Toolbar } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import MyAccountsMenu from './MyAccountsMenu';

export default function ChangePassword() {
  const initialState = {
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
    showErrorMsg: false,
    errorMsg: "",
    showSuccessMsg: false,
  };
  const [state, setState] = useState(initialState);

  const resetState = () => {
    setState((prevState) => {
      return {
        ...prevState,
        ...initialState,
      };
    });
  };

  const validate = (data) => {
    resetState();

    if (data.get("currentPassword").length === 0) {
      setState((prevState) => {
        return {
          ...prevState,
          currentPassword: true,
          showErrorMsg: true,
          errorMsg: "Current password field can't be empty!",
        };
      });

      return false;
    }
    if (data.get("newPassword").length === 0) {
      setState((prevState) => {
        return {
          ...prevState,
          newPassword: true,
          showErrorMsg: true,
          errorMsg: "New password field can't be empty!",
        };
      });
      return false;
    }
    if (data.get("confirmNewPassword").length === 0) {
      setState((prevState) => {
        return {
          ...prevState,
          confirmNewPassword: true,
          showErrorMsg: true,
          errorMsg: "Confirm new password field can't be empty!",
        };
      });
      return false;
    }

    if (data.get("newPassword") !== data.get("confirmNewPassword")) {
      setState((prevState) => {
        return {
          ...prevState,
          newPassword: true,
          confirmNewPassword: true,
          showErrorMsg: true,
          errorMsg: "Confirm password doesn't match with new password!",
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!validate(data)) return;
    const req = {
      oldPassword: data.get("currentPassword"),
      newPassword: data.get("newPassword"),
    };
    apiService
      .changePassword(req)
      .then((res) => {
        if (res.status === 200) {
          setState((prevState) => {
            return { ...prevState, showErrorMsg: false, showSuccessMsg: true };
          });
        } else {
          setState((prevState) => {
            return {
              ...prevState,
              showErrorMsg: true,
              showSuccessMsg: false,
              errorMsg: "Unable to change password",
            };
          });
        }
      })
      .catch((err) => {
        setState((prevState) => {
          return {
            ...prevState,
            showErrorMsg: true,
            showSuccessMsg: false,
            errorMsg: "Unable to change password",
          };
        });
        console.log(err);
      });
  };
  return (
    <div>
      <AppBar position="relative">
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              marginRight: "36px",
              ...{ display: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            eaConnect Dashboard
          </Typography>
          <MyAccountsMenu></MyAccountsMenu>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <div className={state.showErrorMsg ? "show" : "hide"}>
          <Alert variant="outlined" severity="error">
            {state.errorMsg}
          </Alert>
        </div>

        <div className={state.showSuccessMsg ? "show" : "hide"}>
          <Alert variant="outlined" severity="success">
            Password changed!
          </Alert>
        </div>

        <TextField
          error={state.currentPassword}
          margin="normal"
          required
          fullWidth
          id="currentPassword"
          label="Current Password"
          name="currentPassword"
          autoFocus
          type="password"
        />

        <TextField
          error={state.newPassword}
          margin="normal"
          required
          fullWidth
          id="newPassword"
          label="New Password"
          name="newPassword"
          type="password"
        />

        <TextField
          error={state.confirmNewPassword}
          margin="normal"
          required
          fullWidth
          id="confirmNewPassword"
          label="Confirm new Password"
          name="confirmNewPassword"
          type="password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Change Password
        </Button>
        </Box>
      </Container>
    </div>
  );
}