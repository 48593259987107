var moment = require('moment');
let count = 24;
let Sel = [];
var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    for (var i=0; i<= count; i++ ) {
        //var firstDay = new Date(y, m+i, 1);
        var lastDay = new Date(y, m - i, 0);
        Sel.push({"id": i, "label": moment(lastDay).format('MMM YYYY'), "value": moment(lastDay).format('MM/DD/YYYY')})
    };

module.exports = { Sel }